// Bastito main stylesheet
// ====================
//
// Bootstrap
// ---------

@import "~bootstrap";
@import 'components/fonts';
@import "~slick-carousel/slick/slick";

$color_red: #A71918;

body {
  font-family: loew-regular, sans-serif
  strong {
    font-family: loew-bold, sans-serif;
  }
}

h2,h3,h4,h5 {
  font-weight: bold;
  font-family: loew-bold, sans-serif;
  line-height: 1.3;
}

h2 {
  font-size:42px;
  margin-bottom: 1.5em;
  @include media-breakpoint-down(xs) {
    font-size: 28px;
  }
}

h3 {
  font-size:28px;
  margin-bottom: 1.5em;
  @include media-breakpoint-down(xs) {
    font-size: 22px;
  }
}

header {
  background: url('../images/landingpage/headbg.jpg') no-repeat center top;
  background-size: cover;
  min-height: 900px;
  color:white;
  display: flex;
  flex-direction: column;
  position: relative;
  @media(max-width: 960px) {
    min-height: 73vh;
  }
  @media(min-height: 1100px) {
    min-height: 73vh;
  }
  .overlay-img {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../images/landingpage/headbg_crop.png') no-repeat center top;
    background-size: cover;
    display: block;
    content: '';
    left: 0;
    top: 0;
    pointer-events: none;
    @include media-breakpoint-down(xs) {
      position: fixed;
      height: 95vh;
    }
  }
  .menu-row {
    padding: 30px;
  }
  .logo {
    display: block;
    width:96px;
    height: 49px;
    background: url('../images/landingpage/caster_logo.svg') no-repeat center center;
    background-size:contain;
  }
  .headline {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 100px;
    padding-left: 75px;
    max-width: 60%;
    @include media-breakpoint-down(lg) {
      padding-bottom: 40px;
    }
    @include media-breakpoint-down(md) {
      max-width: 90%;
      padding-bottom: 40px;
    }
    @include media-breakpoint-down(xs) {
      padding-bottom: 40px;
      padding-left: 40px;
    }
    h1 {
      font-size: 62px;
      font-weight: bold;
      font-family: loew-extrabold, sans-serif;
      line-height: 1.15;
      @include media-breakpoint-down(xs) {
        font-size: 28px;
      }
      @media(max-width: 340px) {
        font-size: 22px;
      }
    }
    p {
      font-size: 28px;
      margin-top: 40px;
      @include media-breakpoint-down(xs) {
        font-size: 18px;
      }
    }
    a {
      color:white;
      margin-top: 80px;
      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }
      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }
    }
  }
  .mh {
    min-height: 500px;
  }
}

.see-more {
  font-size: 18px;
  display: inline-block;
  padding-left: 2.4em;
  position: relative;
  font-family: loew-bold, sans-serif;
  &::before {
    position: absolute;
    width: 1.75em;
    height: 1.75em;
    background: url('../images/landingpage/down-arrow.svg') no-repeat center center;
    background-size:contain;
    content: '';
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.perex {
  font-size: 20px;
}

ul.checkmarks {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    display: block;
    padding-left: 2.4em;
    position: relative;
    margin-top: 30px;
    color:black;
    font-size: 20px;
    &::before {
      position: absolute;
      width: 1.5em;
      height: 1.5em;
      background:url('../images/landingpage/down-checkmark.svg') no-repeat center center;
      background-size:contain;
      content: '';
      display: block;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
}

.box {
  padding: 75px 75px 75px 0;
  @include media-breakpoint-down(md) {
    padding: 30px;
  }
  &.list {
    padding: 10vh 30px calc(10vh + 50px) 0;
    @include media-breakpoint-down(sm) {
      padding: 50px 30px;
    }
  }
  > .inner {
    position: relative;
  }
  &--red {
    position: relative;
    color:white;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: $color_red;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: calc(((100vw - 1440px) / 2) * -1 - 500px);
      background: $color_red;
      width: calc((100vw - 1440px) / 2 + 500px);
      mix-blend-mode: multiply;
    }
  }
  &--multiply {
    &::before {
      mix-blend-mode: multiply;
    }
  }
}

.infinite-warranty {
  background: url('../images/landingpage/bed-overlayed.jpg') no-repeat center right $color_red;
  padding: 70px;
  display: flex;
  @include media-breakpoint-down(xs) {
    padding: 30px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .icon {
    margin-right: 30px;
    @include media-breakpoint-down(xs) {
      margin-right: 0;
    }
  }
  .info {
    color:white;
    h3 {
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        font-size: 24px;
        text-align: center;
      }
    }
  }
}

section.benefits {
  position: relative;
  overflow: hidden;
  margin-bottom: -50px;
  .bed-sample {
    height: 100%;
    width: 48vw;
    display: block;
    position: absolute;
    background: url('../images/landingpage/bed_right.jpg') no-repeat center right;
    background-size: cover;
    top: 0;
    left: -15px;
    @include media-breakpoint-down(md) {
      left:-40px;
      height: 30vh;
      position: relative;
      width:calc(100% + 80px)
    }
  }
}

section.making-steps {
  overflow: hidden;
  position: relative;
  margin-top: -30px;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    background:url('../images/landingpage/produktion.jpg') no-repeat center center;
    background-size: cover;
    width: 31%;
    height: 80%;
    content: '';
    display: block;
    @media(max-width: 1700px) {
      width: 18%;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.steps {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  position: relative;
  padding-bottom: 50px;
  padding-top: 20px;
  background:white;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
  &::before {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    border:2px solid #E8E8E8;
  }
  .while {
    position: absolute;
    padding: 10px 20px;
    background: white;
    bottom: -22px;
    color:$color_red;
    font-size: 16px;
    font-family: loew-bold, sans-serif;
  }
  .step-i {
    text-align: center;
    margin-top: 20px;
    .ico {
      text-align: center;
    }
    span {
      font-size: 16px;
      font-weight: bold;
      display: block;
      margin-top: 10px;
      @include media-breakpoint-down(md) {
        font-size: 13px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
    &:nth-of-type(6) {
      padding-right: 10px;
    }
  }
  .step-arr {
    width: 39px;
    height: 39px;
    display: block;
    background: url('../images/landingpage/icons/left-arrow.svg') no-repeat center center;
    background-size:contain;
    @include media-breakpoint-down(lg) {
      width: 25px;
      height: 25px;
    }
    @include media-breakpoint-down(sm) {
      transform: rotate(90deg);
      margin-top: 20px;
    }
  }
}

.btn-cta {
  display: inline-block;
  background:$color_red;
  color:white;
  font-size: 16px;
  font-family: loew-bold, sans-serif;
  padding: 15px 40px;
  border:2px solid $color_red;
  transition: all .25s ease;
  &:hover {
    text-decoration: none;
    background: rgba($color_red, 0);
    color:$color_red;
  }
}

.close-btn {
  width: 50px;
  height: 50px;
  background: url('../images/landingpage/error.svg') no-repeat center center;
  background-size: contain;
  display: block;
  position: absolute;
  bottom: -25px;
}

section.making-steps {
  padding: 10vh 0;
}

section.gallery {
  margin-bottom: 100px;
}

.gallery-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .image {
    width: calc((100%/3) - 1px);
    flex: 0 0 calc((100%/3) - 1px);
    &:nth-child(3n) {
      width: calc(100%/3);
      flex: 0 0 calc(100%/3);
    }
    margin-top: 1px;
    overflow: hidden;
    display: block;
    position: relative;
    background:$color_red;
    @include media-breakpoint-down(lg) {
      width: calc((100%/3) - 1px);
      flex: 0 0 calc((100%/3) - 1px);
      &:nth-child(3n) {
        width: calc(100%/3);
        flex: 0 0 calc(100%/3);
      }
    }
    @include media-breakpoint-down(md) {
      width: calc(100%/3 - 1px);
      flex: 0 0 calc(100%/3 - 1px);
      &:nth-child(3n) {
        width: calc(100%/3);
        flex: 0 0 calc(100%/3);
      }
    }
    @include media-breakpoint-down(sm) {
      width: calc(50% - 1px);
      flex: 0 0 calc(50% - 1px);
      &:nth-child(3n) {
        width: calc(50%);
        flex: 0 0 calc(50%);
      }
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
      flex: 0 0 100%;
      &:nth-child(3n) {
        width: 100%;
        flex: 0 0 100%;
      }
    }
    > img {
      width: 100%;
      height:100%;
      object-fit: cover;
    }
    &::after {
      background:url('../images/landingpage/icons/zoom-ico.svg') no-repeat center center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      content: '';
      position: absolute;
      opacity: 0;
    }
    &:hover {
      img {
        mix-blend-mode: multiply;
      }
      &::after {
        opacity: 1;
      }
    }
  }
}

.contact-modal {
  background: url('../images/landingpage/bed-overlayed-form.jpg') no-repeat center right $color_red;
  padding:0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 0;
  color:white;
  overflow: auto;
  &::before {
    position: absolute;
    width: 100%;
    height: 20px;
    background:white;
    bottom: 0;
    left: 0;
    content:'';
    display: block;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  > .container {
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-down(sm) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  h2 {
    color:white;
    font-size: 36px;
    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }
  &.animIn {
    animation-name: slideIn-h;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    &::before {
      animation-name: slideIn-before;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }
  }
  &.animOut {
    animation-name: slideOut-h;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    &::before {
      animation-name: slideOut-before;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    }
  }
}

.button-send {
  display: inline-block;
  color:white;
  font-size: 16px;
  font-family: loew-bold, sans-serif;
  padding: 15px 40px;
  border:2px solid white;
  transition: all .25s ease;
  background:transparent;
  &:hover {
    text-decoration: none;
    background: white;
    color:$color_red;
  }
}

.most-sold {
  margin-bottom: 125px;
  h2 {
    margin-bottom: 0.75em;
  }
}

.calculation {
  margin-top: 30px;
  .red-box {
    background:url('../images/landingpage/redphoto.jpg') no-repeat center right #A71918;
    padding: 75px 50px;
    display: flex;
    justify-content: space-between;
    color:white;
    font-size:28px;
    font-weight: bold;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      padding: 30px 15px;
      font-size: 22px;
    }
    .contact-btn {
      background: #FFD024;
      border-color:#FFD024;
      color:black;
      transition: .25s all ease;
      @include media-breakpoint-down(sm) {
        margin-top: 15px;
      }
      &:hover {
        border-color:#FFD024;
        color:white;
        background:transparent;
      }
    }
  }
}

.testimonials {
  @include media-breakpoint-down(xs) {
    margin-bottom: 50px;
  }
  span.next-slide {
    font-size: 18px;
    font-weight: bold;
    display: block;
    text-align: right;
    padding-right: 38px;
    position: relative;
    color:#A71918;
    cursor: pointer;
    &:hover {
      &::after {
        right: -10px;
      }
    }
    &::after {
      display: block;
      content: '';
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      right: 0;
      width: 28px;
      height: 28px;
      background: url('../images/landingpage/arrow-left-red.svg') no-repeat center center;
      transition: .25s all ease-in-out;
    }
  }
  .image-inner {
    position: relative;
    padding-bottom: 110%;
    height: 0;
    img {
      position: absolute;
      top: 0;
      right: 0;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
      height: auto;
      img {
        position: relative;
        max-width: 100%;
        right: auto;
        top: auto;
      }
    }
  }
  .quote-text {
    padding-left: 40px;
    padding-top: 40px;
    position: relative;
    &::before {
      position: absolute;
      display: block;
      width: 74px;
      height: 61px;
      background:url('../images/landingpage/right-quotation-sign.svg') no-repeat center center;
      background-size: contain;
      content: '';
      top:10px;
      left: 10px;
      z-index: -1;
    }
    p {
      font-size: 16px;
      color: #000000;
    }
    span {
      font-weight: bold;
      font-size: 20px;
      display: block;
      text-align: right;
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }
}

.next-container {
  margin-top: -150px;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

.sample-beds {
  .sample-bed {
    margin-top: 20px;
    display: block;
  }
  .sample-bed-inner {
    height: 420px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    color:white;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    padding: 15px 15px 40px;
    text-decoration: none;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: flex-end;
    }
    &:hover {
      text-decoration: none;
      &::before {
        height:100%;
      }
    }
    &::before {
      position: absolute;
      content: '';
      display: block;
      height: 50%;
      width: 100%;
      left: -10px;
      bottom: -10px;
      background:#A71918;
      mix-blend-mode: multiply;
      transition: all .25s ease-in-out;
    }
    h3 {
      font-size: 16px;
      font-weight: bold;
      position: relative;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    span.price {
      position: relative;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      color:white;
      strong {
        color:#FFD024;
      }
    }
  }
}

.config-shortcut {
  padding-top: 140px;
  padding-bottom: 140px;
  margin-top: -20px;
  overflow: hidden;
  margin-bottom: 50px;
  @include media-breakpoint-down(sm) {
    margin-top: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h3 {
    font-size: 36px;
    margin-bottom: 1em;
    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }
  p {
    margin-bottom: 1em;
  }
  .image-holder {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top:50%;
      transform: translateY(-50%);
      @include media-breakpoint-down(sm) {
        position: relative;
        transition: none;
        top: auto;
        left: auto;
        max-width: 100%;
        transform: none;
        margin-top:25px;
      }
    }
  }
}

@keyframes slideIn-h {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    max-height: 100vh;
    opacity: 1;
  }
}
@keyframes slideIn-before {
  0% {
    height: 10px;
    opacity: 0;
  }
  50% {
    height: 100px;
    opacity: 1;
  }
  100% {
    height: 10px;
    opacity: 0;
  }
}
@keyframes slideOut-h {
  from {
    max-height: 100vh;
    opacity: 1;
  }
  to {
    opacity: 0;
    max-height: 0;
  }
}
@keyframes slideOut-before {
  0% {
    height: 10px;
    opacity: 0;
  }
  50% {
    height: 100px;
    opacity: 1;
  }
  100% {
    height: 10px;
    opacity: 0;
  }
}

//.slick-list {
//  overflow: visible;
//}

.bedModal {
  padding: 0 !important;
  .modal-dialog {
    max-width: 100vw;
    margin: 0;
    .modal-content {
      min-height: 100vh;
      border:0;
      border-radius: 0;
      background: $color_red;
      button.close {
        position: absolute;
        z-index: 10;
        right: 20px;
        top: 10px;
        font-size: 3vw;
        line-height: 2vw;
        @include media-breakpoint-down(sm) {
          font-size: 50px;
          line-height: 36px;
        }
      }
      .text-content {
        padding:30px;
        @include media-breakpoint-down(sm) {
          padding: 50px 20px 20px;
        }
      }
      .slide-holds {
        position: absolute;
        width:calc(50% - 25px);
        height:calc(100% - 50px);
        left: 25px;
        top: 25px;
        bottom:25px;
        overflow: hidden;
      }
      .half-image {
        position: absolute;
        width:calc(50% - 25px);
        height:calc(100% - 50px);
        left: 25px;
        top: 25px;
        bottom:25px;
        object-fit: cover;
        object-position: center center;
        @include media-breakpoint-down(sm) {
          display: block;
          width: 100%;
          height: 250px;
          left: auto;
          top: auto;
          bottom: auto;
          mix-blend-mode: multiply;
          opacity: 0.5;
        }
      }
      .overlay {
        display: none;
        width: 100%;
        height:125px;
        top: 125px;
        position: absolute;
        background-image: linear-gradient(0deg, #A71918 0%, rgba(167,25,24,0.00) 100%);
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }
      ul.pars {
        list-style: none;
        padding: 0;
        &.price {
          font-size: 18px;
        }
      }
    }
  }
}

.short-contact {
  margin-bottom: 70px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
  }
  .quick-b-conts {
    .c {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top:15px;
      img {
        margin-right: 15px;
      }
      span,a {
        font-weight: bold;
        color:#A71918;
      }
      &.p {
        span,a {
          font-size: 42px;
          @include media-breakpoint-down(sm) {
            font-size: 20px;
          }
        }
        img {
          max-width: 50px;
          @include media-breakpoint-down(sm) {
            max-width: 30px;
          }
        }
      }
      &.m {
        span,a {
          font-size: 28px;
          @include media-breakpoint-down(sm) {
            font-size: 18px;
          }
        }
        img {
          max-width: 45px;
          @include media-breakpoint-down(sm) {
            max-width: 26px;
          }
        }
      }
    }
  }
}


.modal.discount {
  .modal-lg {
    max-width: 920px;
    .modal-content {
      background:url('../images/landingpage/modal-bg-red.jpg') no-repeat center right #A71918;
      background-size: auto 100%;
      color:white;
      padding:55px;
      border-radius: 0;
      position: relative;
      border:0;
      @include media-breakpoint-down(sm) {
        padding: 25px;
      }
      p.h {
        font-size: 32px;
        margin: 0;
        line-height: 1.25;
        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }
      }
      p.s {
        margin: 30px 0 0;
        color: #FFD024;
        font-weight: bold;
      }
    }
  }
}